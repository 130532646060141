import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'Valos',
  title: 'Actividades de voluntariado',
  logo: Logo,
};

export default config;
